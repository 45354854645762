.grid-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-gap: 10px;
}
.grid-container-pods {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(3, 1fr);
  grid-gap: 10px;
}

.item2 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 200px;
}
.item1 {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 410px;
}

.grid-container-new {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-column-gap: 1px;
  text-align: center; /* Adjust the gap width as needed */
  border: 0.2rem solid rgba(191, 191, 191, 0.7);
  margin-top: 20px;
}

.grid-item {
  border-right: 1px solid black; /* Add a vertical line separator */
  padding: 10px;
}

.grid-item:last-child {
  border-right: none; /* Remove the separator for the last grid item */
}

/* Sixth section */
.details {
  box-sizing: border-box;
}

.topic {
  padding: 20px 0px;
  font-size: 30px;
}

.summary {
  font-size: 20px;
  outline: none;
  text-align: left;
  padding-bottom: 20px;
}

.summaryptag {
  font-size: 16px;
  outline: none;
  text-align: left;
  padding-bottom: 20px;
}

.about p {
  margin: 20px 0px;
}

.container1 {
  padding: 50px;
  position: sticky;
  display: flex;
  height: 100vh;
  overflow: hidden;
}

.left1 {
  flex: 1;
  overflow-y: auto;
  z-index: 1;
  padding-right: 50px;
}

.right1 {
  position: sticky;
  padding: 30px;
  margin: 50px;
  width: 300px;
  text-align: center;
  height: auto;
  border: 0.0625rem solid rgba(191, 191, 191, 0.7);
  z-index: 2;
  display: block;
  height: 400px;
}

.right1 button {
  margin: 10px 0px;
  width: 300px;
  padding: 10px;
  font-size: 16px;
}

.right1 > h3 {
  text-align: center;
  margin-bottom: 20px;
}

.right1 p {
  text-align: center;
  margin-bottom: 20px;
}

.container-icon {
  width: 150px;
  height: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  border: 0.0625rem solid rgba(191, 191, 191, 0.7);
  align-items: center;
  padding: 20px;
  margin: 0px 20px;
}

.container-icon:first-child {
  margin-left: 0;
}

.icon {
  margin-bottom: 20px;
}

.description {
  text-align: center;
}

.grid-items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
}

.grid-items li {
  margin-top: 20px;
  list-style-type: disc;
  font-size: 20px;
}

.grid-items:first-child {
  margin-left: 20px;
}
