.mapContainer {
  height: 100vh;
  width: 100%;
  display:flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
}

.circle {
  /* background-color: #d83b01; */
  border-radius: 50%;
  height: 2.5em;
  position: relative;
  width: 2.5em;
  border: 1px solid transparent;
}

.circleText {
  text-align: center;
  position: absolute;
  /* transform: translate(-50%, -50%); */
  width: fit-content;
  padding: 2px;
  background-color: white;
  border: 1px solid black;
}
