.location .box{
    position: relative;
    border-radius: 5px;
}
.location img{
    border-radius: 5px;
}
.box{
    position: relative;
}
/* .explorebox{
    display: flex;
    align-items: center;
    justify-content: center;
} */
.location .overlay{
    position: absolute;
    top: 0;
    left: 0;
    /* display: flex; */
    /* flex-direction: column;
    justify-content: center;
    align-items: center; */
    /* height: 20px;
    width: 80%; */
    /* height: 10rem;
    width: 15rem; */
    background: rgba(15, 28, 47, 0.585);
    color: #fff;
    border-radius: 5px;
    padding: 5px;
    /* z-index: 2222; */
}
 .location .overlay>h5{
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
}
 .location .overlayy::after{
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100px;
    /* width: 15rem;
    height: 5rem; */
    background: rgb(15 28 47 / 30%);
    z-index: -1;
    /* margin: auto; */
    margin: 30px;
    border-radius: 5px;
} 
.location h5{
    font-size: 18px;
    font-weight: 500;
}
.location label{
    color: #fff;
    margin-right: 20px;
    opacity: 0.8;
}

@media screen and (max-width:800px) {
    .location .overlay>h5{
        font-size: 10px;
    }
}

@media screen and (max-width:500px) {
    section .recenttitle>h1{
        font-size: 30px;
    }
    section .explorebox{
        height: 7rem;
      
    }
}
@media screen and (max-width:450px) {
    section .recenttitle>p{
        font-size: 14px;
    }
    .container .overlay>h5{
        font-size: 10px;
    }
}
@media screen and (max-width:450px) {
    section .recenttitle>h1{
        font-size: 25px;
    }
}