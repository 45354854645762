.awardtitle>h1,p{
    margin: 0px;
    padding: 0px;
}
.awardtitle{
    margin-top: 30px;
    text-align: center;
}
.box>h1{
    margin: 0px;
    padding: 0px;
    font-size: 20px;
}
.box>p{
    color: #fff;
}

.awards{
    background-color: #122947;
    color: #fff;
    text-align: center;
    height: 20rem;

}
.awards .awardtitle p{
    color: #27ae60;
}
.awards .heading h1{
    color: #fff;
    font-size: 30px;
    font-weight: 400;
}

.awards .icon{
    margin: auto;
    background: rgba(255,255 ,255 ,0.1 );
    width: 100px;
    height: 50px;
    font-size: 32px;
    border-radius: 2% 20%;
}

.mbottom{
    margin-bottom: 40px;
}

@media screen and (max-width:520px) {
    .grid4{
        grid-template-columns: repeat(2,1fr);
    }
    .awards{
       
        height: 25rem;
    
    }
    .awards .awardtitle>h1{
        font-size: 16px;
    }
    
}