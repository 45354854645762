.container {
    min-height: 100vh;
    display: flex;
    background-color: rgb(232, 232, 232);
    justify-content: center;
}

.profileSection {
    margin-top: 90px;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 30px;
    width: 90%;
}

.submitButton {
    display: flex;
    justify-content: center;
}

.submitButton button {
    color: white;
    font-size: 16px;
    cursor: pointer;
    background-color: #192440;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    width: 100%;
    max-width: 200px;
}

.loader {
    width: fit-content;
    margin: 100px auto;
}