.featured .box{
    box-shadow: 0 0 20px 0 rgb(121 121 138 /18%);
    border-radius: 6px;
    text-align: center;
    padding: 30px ;
    cursor: pointer;
}

.featured img{
    width: 65px;
    height: 65px;
    margin: auto;
}
.box>h4{
    margin: 0px;
    padding: 0px;
}
.featuedtitle{
    text-align: center;
}
.featuedtitle h1{
    margin: 0px;
    margin-top: 20px;
    padding: 0px;
    font-size: 40px;
}
.featuedtitle p{
    color: #2d3954;
    margin: 0px;
    padding: 0px;
    margin-top: 5px;
}

@media screen and (max-width:830px) {
    .featured .featuredbox{
        padding: 20px ;
    }
    
}
@media screen and (max-width:800px) {
    .featured .featuredbox{
        padding: 10px ;
    }
    .featured .featuredbox>h4{
        font-size: 14px;
    }
    .featured .featuredbox>label{
        font-size: 12px;
    }
}

@media screen and (max-width:720px) {
    .grids{
        grid-template-columns: repeat(3,1fr);
    }
}

@media screen and (max-width:530px) {
    .featuedtitle>h1{
        font-size: 30px;
    }
    .grids{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 20px;
    }
}
@media screen and (max-width:400px) {
    .featuedtitle>h1{
        font-size: 24px;
    }
}