ul {
  padding: 0px;
  margin: 0px;
}
ul > li {
  list-style: none;
  font-size: 20px;
  padding: 5px 20px;
  font-weight: 400;
}
ul > li:hover {
  color: #27ae60;
  transition: 0.5s;
}

.logo > img {
  /* border: 2px solid red; */
  /* width: 170px; */
  height: 3rem;
  /* background-size: 50% 100%; */
  width: 6rem;
  /* margin: 0px; */
}

.btns > button {
  margin-right: 20px;
  padding: 5px 10px;
  font-size: 18px;
  background: none;
  /* background-color: #427b01; */
  border: 1px solid black;
  color: #427b01;
  cursor: pointer;
  border-radius: 0px;
}
.btn:hover {
  /* background-color: rgb(219, 64, 146); */
  background-color: #427b01;
  color: #fff;
  border-radius: 7px;
}
.logoutbtn {
  margin-right: 20px;
  padding: 8px 15px;
  font-size: 18px;
  border-radius: 7px;
  background-color: #e10202;
  color: #fff;
  border: none;
  cursor: pointer;
}
.logoutbtn:hover {
  /* background-color: rgb(219, 64, 146); */
  background-color: #427b01;
  color: #fff;
  border-radius: 7px;
}
header {
  background-color: #fff;
  height: 10vh;
  box-shadow: 0 5px 30px rgb(0 22 84 / 10%);
  padding: 15px 0;
  position: sticky;
  top: 0;
  z-index: 2225;
}
header a:hover {
  color: #27ae60;
  transition: 0.5s;
}
.toggle {
  display: none;
}
.togglebtn {
  padding: 5px 15px;
  font-size: 24px;
  line-height: 10px;
  border-radius: 7px;
  background: none;
  color: #000000;
  border: none;
}

@media screen and (max-width: 840px) {
  header {
    padding: 0px;
    height: 8vh;
  }
  header img {
    margin-top: 5px;
  }
  header .btn {
    display: none;
  }
  .logoutbtn {
    display: none;
  }
  header .nav .flex {
    display: none;
    margin-top: 20px;
  }
  header .toggle {
    display: block;
  }
  .small {
    position: absolute;
    top: 8vh;
    left: 0px;
    width: 100%;
    background-color: #27ae60;
    padding: 20px;
  }

  header li a {
    display: inline-block;
    margin-bottom: 20px;
    color: #fff;
  }
  header li a:hover {
    background-color: #fff;
    padding: 5px 8px;
    border-radius: 10px;
    color: #27ae60;
  }
}

@media screen and (max-width: 1150px) {
  ul > li {
    font-size: 18px;
    padding: 5px 10px;
    font-weight: 300;
  }
  .btns > button {
    margin-right: 20px;
    padding: 5px 15px;
    font-size: 18px;
  }
}
@media screen and (max-width: 1000px) {
  ul > li {
    font-size: 16px;
    padding: 5px 10px;
    font-weight: 200;
  }
  .btns > button {
    margin-right: 20px;
    padding: 5px 15px;
    font-size: 16px;
  }
}
@media screen and (max-width: 950px) {
  .nav {
    padding: 10px;
  }
  ul > li {
    font-size: 16px;
    padding: 5px 5px;
    font-weight: 200;
  }
  .btns > button {
    margin-right: 20px;
    padding: 5px 15px;
    font-size: 16px;
  }
}
@media screen and (max-width: 910px) {
  .nav {
    padding: 10px;
  }
  ul > li {
    font-size: 14px;
    padding: 5px 5px;
    font-weight: 400;
  }
  .btns > button {
    margin-right: 20px;
    padding: 5px 15px;
    font-size: 15px;
  }
}
