.container>h1,h3,p{
    margin: 0;
    padding: 0;
}
.text>h1{
    margin: 0;
    padding: 0;
    color: #fff;
}
.text>p{
    color: #fff;
}
.text>h1{
    font-weight: 700;
}
.text>p{
    font-size: 18px;
}
.footerContact{
    background-color: #27ae60;
    padding: 40px 0;
}
.footerContact h1{
    font-size: 40px;
   
}
.footercontent{
    max-width: 80%;
}
.btn5 {
    background: #fff;
    border: 5px solid #27ae601f;
    border-radius: 50px;
    color: #27ae60;
    font-size: 20px;
    font-weight: 400;
    padding: 20px 40px;
    cursor: pointer;
}

footer{
    background-color: #1d2636;
    padding: 50px 0;
    color:#fff;
}

footer .container{
    display: grid;
    grid-template-columns: 7fr 2fr 2fr 1fr;
    grid-gap: 20px;
}
footer .box>ul>li{
    padding: 0;
}
footer img{
    width: 150px;
}
footer p{
    color: grey;
    margin: 20px 0;
}
footer input{
    background-color: #fff;
    padding: 17px;
    width: 70%;
    font-size: 24px;
    border-radius: 5px;
    
}
/* .input>input{
    height: 100%;
} */
.input{
    /* border: 2px solid red; */
}
footer h3{
    font-weight: 500;
    margin-bottom: 30px;
}
footer ul{
    display: block;
}
footer ul>li{
    display: block;
    color: grey;
    margin-bottom: 20px;
    cursor: pointer;
}
.btn6{
    background: #27ae60;
    border: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    font-weight: 700;
    font-size: 22px;
    padding: 18px 20px;
    margin: 3px;
}

.footerdata{
    border: 2px solid red;
}

@media screen and (max-width:800px) {
    footer .container{
        grid-template-columns: repeat(2,1fr);
        grid-gap: 50px;
    }
    .container .btn5 {
      
        font-size: 14px;
        font-weight: 300;
        padding: 10px 10px;
    }
    .container .text>p{
        font-size: 12px;
    }
   
}

@media screen and (max-width:500px) {
    .container .btn5 {
        font-size: 12px;
        font-weight: 100;
        padding: 2px;
    }
}
@media screen and (max-width:400px) {
    .container .btn5 {
        font-size: 10px;
        font-weight: 50;
        padding: 2px;
    }
    .container .text>h1{
        font-size: 12px;
    }
    .container .text>p{
        font-size: 11px;
    }
}

@media screen and (max-width:1000px) {
    footer .box>ul>li{
        font-size: 14px;
    }
    footer .box>h3{
        font-size: 14px;
    }
    
    .logo>h2{
        font-size: 22px;
    }
    .footerContact .text>h1{
        
        font-size: 25px;
    }
    .btn5{
        font-size: 16px;
        padding: 20px 20px;
    }
    .btn6{
       
        font-weight: 400;
        font-size: 18px;
        padding: 11px 12px;
        margin: 2px;
    }
    footer input{
        padding: 12px;
        width: 70%;
        
        font-size: 15px;

        
    }
}
@media screen and (max-width:700px) {
    .container .text>h1{
        font-size: 16px;
    }
    .btn6{
       
        font-weight: 200;
        font-size: 12px;
        padding: 8px 8px;
    }
    footer input{
       
        padding: 5px;
        width: 100%;
        font-size: 12px;  
    }
    .box .input>input{
        margin: 0px;
        border: 0px;
    }
    
}

