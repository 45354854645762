.outerContainer {
    /* border: 2px solid red; */
    height: 100%;
    padding: 10px;
    margin-top: 300px;
    /* background-image: url('https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSPrVr2DTY0w_zN6XL4NDZZLo2esP5pjaytPA&usqp=CAU');
    background-repeat: no-repeat;
    background-size: cover; */
}

.innerContainer {

    height: 100vh;
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    /* border: 2px solid rgb(3, 3, 3); */
    padding: 10px;
}

.addpropertytitle>h1 {
    margin: 10px;
    color: #27ae60;
}

.imgContainer {
    /* border: 2px solid rgb(16, 171, 86); */
    width: 70%;
    height: 40rem;
    /* background-size: cover; */
}

.formContainer {
    /* border: 2px solid rgb(221, 225, 26); */
    width: 100%;
    height: 40rem;
    padding: 10px;
}

.addpropertytitle {
    text-align: center;
    padding: 10px;
}

.addpropertyForm {
    background: none;
    padding: 20px;
    width: 45vw;
    margin-top: 10px;
    text-align: center;

}

.propertylabel {
    /* border: 2px solid rgb(27, 2, 2);  */
    width: 60%;
}

.addpropertyItem {
    /* border: 2px solid red; */
    display: flex;
    flex-direction: column;
    width: 80%;
    /* justify-content: center; */
    /* align-items: center; */
    margin: auto;
}

.addpropertyItem>input {
    width: 30vw;
    outline: #27ae60;
    border-bottom: 1px solid black;
    background-color: white;
    font-size: 18px;
    padding: 5px 5px;
    /* padding-top: 5px; */
    margin-bottom: 1rem;
    margin-top: 0.5rem;
}

.inputP {
    text-align: left;
}

.generateBtn {
    background-color: #27ae60;
    color: #fff;
    padding: 10px 10px;
    margin-top: 1rem;
    font-size: 18px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
}

.addbtn {
    background-color: #27ae60;
    color: #fff;
    padding: 10px 10px;
    margin-top: 4rem;
    font-size: 18px;
    font-weight: 700;
    border: none;
    border-radius: 5px;
}

.typeOptions {
    padding: 10px 10px;
    font-size: 16px;
    width: 30vw;
    margin-top: 10px;
}

.textarea {
    max-width: 31vw;
    resize: none;
    overflow-y: hidden;
    box-sizing: border-box;
    padding: 10px;
    margin-top: 1rem;
    height: 150px;
}

.bulletContainer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    width: 100%;
}

.input {
    text-align: left;
    margin: 10px 0;
}

.removeButton {
    background-color: #ff4d4d;
    color: white;
    cursor: pointer;
    width: max-content;
    padding: 5px;
    width: 10%;
    margin-bottom: 10px;
    border: none;
    border-radius: 4px;
}

.addButton {
    background-color: #4CAF50;
    color: white;
    cursor: pointer;
    width: max-content;
    padding: 10px;
    border: none;
    border-radius: 4px;
    margin: 10px 0;
}

@media screen and (max-width:1024px) {
    .imgContainer {
        height: 30rem;
    }
}

@media screen and (max-width:900px) {
    .addpropertyItem>input {
        width: 38vw;
    }

}

@media screen and (max-width:800px) {
    .outerContainer {
        height: 100%;
        padding: 10px;
    }

    .innerContainer {
        flex-direction: column;
        height: 177vh;
    }

    .imgContainer {

        width: 100%;
        height: 30rem;

    }

    .addpropertyForm {
        width: 75vw;
        margin: auto;
    }

    .addpropertyItem>input {
        width: 55vw;
    }

    .addpropertyForm {
        padding: 10px;
    }
}

@media screen and (max-width:500px) {
    .innerContainer {
        height: 150vh;
    }

    .imgContainer {

        width: 100%;
        height: 20rem;

    }

}

@media screen and (max-width:400px) {
    .addpropertytitle>h1 {
        font-size: 24px;
    }
}