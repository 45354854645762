.formContainer{
    display: flex;
    height: fit-content;
    flex-direction: column;
    background-color: white;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    padding: 20px;
    width: 90%;
    border-radius: 20px;
    box-shadow: 10px 5px 20px lightgray;
}

.heading{
    font-size: 1.5rem;
    text-align: center;
    font-weight: 450;
    padding: 20px;
    margin-top: 20px;
}

.formContainer>div{
    width: 90%;
    /* margin: 5px; */
}

.answers{
    border: 1px solid gray;
    border-radius: 10px;
    padding: 8px;
    margin: 10px;
    width: 100%;
}

.label{
    margin: 0 10px;
    display: block;
}

.btn{
    color: green;
    background-color: white;
    border: 1px solid green;
    width: fit-content;
    margin: 10px;
    padding: 8px 11px;
    cursor: pointer;
}

.btn:hover{
    color: white;
    background-color: green;
}

.flex{
    display: flex;
    justify-content: space-between;
}
