.react-chatbot-kit-chat-container {
  position: fixed !important;
  width: 55vh !important;
  max-height: 60vh !important;
  max-width: 80vh !important;
  height: 56vh !important;
  margin-top: 15vh !important;
  margin-left: 70vw !important;
  background-color: white !important;
  transition: transform 0.05s ease-in-out !important;
  cursor: pointer !important;
  animation: slideInRight 0.2s ease-in-out !important;
  border-radius: 20px !important;
  z-index: 1000;
 
}
.react-chatbot-kit-chat-inner-container {
  height: 72vh;
}
.react-chatbot-kit-chat-header {
/* background-color: #0f3c72; */
background: #80b786;
background: -webkit-linear-gradient(0deg, #80b786 0%, #6f9d68 100%);
background: linear-gradient(0deg, #80b786 0%, #6f9d68 100%);
 color: white;
 z-index: 1000 !important;
}
.react-chatbot-kit-chat-message-container{
height: 100%;
background-color: white !important;
}

.react-chatbot-kit-chat-bot-message {
  font-size: 0.7rem !important;
  margin-top: -13px;
  margin-left: 15px !important;
  width: 12vw !important;
  max-width: 70vh !important;
}
.react-chatbot-kit-user-chat-message {
  font-size: 0.7rem !important;
  margin-bottom: 10px;
  margin-right: 15px ;
  width: 12vw !important;
}
.react-chatbot-kit-chat-input-container{
  bottom: -50%;
  z-index: 1000;
  /* height:5vh !important; */
  /* background-color: red !important; */

}
.react-chatbot-kit-chat-input-form {
  background-color: whitesmoke !important;
  height: 100% !important;
}

.fwidget{
  margin-left: 2.9vw;
  display: flex;
  width: 15vw !important;
  flex-wrap: wrap !important;
}

.start{
font-size: 0.6rem;
margin-left: 2px;
padding: 2px;
background-color: rgba(243, 220, 47, 0.388);
color: #0f3c72 ;
border-style: solid;
border-width: 0.5px;
border-radius: 7px;

}


.start:hover{
  padding: 2px;
  border: black;
  color: white;
  background: black;
  transform: scale(1.1);
  transition-duration: 0.1s;
}

.swidget{
  margin-left: 2.9vw;
  display: flex;
 
}

.sstart{
  font-size: 0.6rem;
  margin-left: 2px;
  padding: 6px;
  background-color: rgba(243, 220, 47, 0.388);
  color: #0f3c72 ;
  border-style: solid;
  border-width: 0.5px;
  border-radius: 10px;
  }

.sstart:hover{
  border: black;
  color: white;
  background: black;
  transform: scale(1.1);
  transition-duration: 0.5s;
}


@keyframes slideInRight {
  0% {
    transform: translateY(50%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideOutLeft {
  0% {
    transform: translateY(0);
    opacity: 1;
  }
  100% {
    transform: translateY(-50%);
    opacity: 0;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#cbt{
  display: none  !important;
}

.cbct{
  display: none;
}

.mainbot{

  font-size: 40px;
color: #6f9d68 ;
  z-index: 1000 !important;
  
}
.mainbot2{
  margin-top: 20px;
  font-size: 50px;
  color:  #80b786 ;
  margin-bottom: 20px;
  z-index: 1000 !important;
}
.chatbot{
  z-index: 1000 !important;

}



@media (max-width: 600px){
  .react-chatbot-kit-chat-container{
    margin-left: 0vw !important;
    margin-top: 10vw !important;
    width: 100vw !important;
  }
  .react-chatbot-kit-chat-inner-container{
    height: 73vh !important;
    padding-bottom: 4vh !important;
  }
  .react-chatbot-kit-chat-bot-message{
    width: 40vw !important;
  }
  .react-chatbot-kit-user-chat-message{
    width: 40vw !important;
  }
  .fwidget {
    margin-left: 10vw !important;
    display: flex !important;
    flex-wrap: wrap !important;
    width: 40vw !important;
  }
  
}