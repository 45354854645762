.searchbar{
    background-image:url('https://funny-daffodil-350bc9.netlify.app/static/media/banner.1a3c8a0a448e8e24f6a0.png') ;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    height: 30rem;
    width: 90%;
    margin: auto;
   border-radius: 5px;
}
.searchbar .container{
    padding-top: 5%;
}
/* .box{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
} */

form{
    background-color: rgb(157, 157, 157,0.2);
    border-radius: 15px;
    margin-top: 30px;
    padding: 0 20px;
    border: 1px solid rgb(255, 232, 82);
    margin-bottom: 20px;
    
}

.searchitem{
     padding: 0px 15px;
}
form .searchitem input{
    padding:6px;
    width: 100%;
    background-color: rgb(198, 198, 198);
    border: 1px solid rgba(255, 0, 0, 0.2);
    margin-top: 5px;
    border-radius: 5px;
}
form span{
    font-size: 16px;
    color: #fff;

}
input::placeholder{
    font-size: 12px;
    color: rgb(91, 91, 91);
}
form {
    padding: 5px;
    border: 1px solid rgba(128,128,128,0.2);
}
.heading{
    text-align: center;
    width: 60%;
    margin: auto;
}
.heading h1{
    color: #fff;
    font-weight: 600;
    font-size: 60px;
    text-transform: capitalize;
    /* border: 2px solid red; */
    margin-top: 0px;
    margin-bottom: 0px;
}
.heading p{
    color: #fff;
    opacity: 0.5;
    /* border: 2px solid red; */
    margin-top: 0px;
    margin-bottom: 0px;
}
input{
    background: none;
    border: none;
    outline: none;
}
.sbtn{
    margin-right: 10px;
    margin-top: 15px;
}
.sbtn .button{
    margin-right: 0px;
    padding: 6px 35px;
    font-size: 20px;
    border-radius: 7px;
    background-color: #27ae60;
    color: #fff;
    border: none;
    
}
.sbtn>button:hover{
    /* background-color: rgb(219, 64, 146); */
    cursor: pointer;
    color: white;

}
.searchitem{
    /* border: 2px solid red; */
}

@media screen and (max-width:900px) {
    .searchbar{
        height: 32rem;
    }
    .heading h1{
        font-size: 30px;
    }
   
    .searchform{
        display: flex;
        flex-wrap: wrap;

        
    }
    .searchform>input{
        width: 100%;
        padding: 5px;
    }
    .searchitem{
        padding: 0px 15px;
   }
    .sbtn{
        margin: auto;
        margin-top: 25px;
        margin-bottom: 10px;
    }
    /* .sbtn {
        margin: auto;
        margin-top: 10px;
        margin-bottom: 10px;
    } */
   
}
 
@media screen and (max-width:1000px) {
    .heading{
    }
    .heading h1{
        font-weight: 500;
        font-size: 40px;
    }
    .heading p{
        opacity: 0.5;
    }
    
}

@media screen and (max-width:785px) {
    .searchform{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 50%;
        margin: auto;
        margin-top: 20px;
    }
    .heading h1{
        font-size: 24px;
    }
    .heading P{
        font-size: 14px;
    }
    form .searchitem input{
        padding:2px;
        margin: 2px;
        margin-top: 2px;

    }
    .searchbar .container{
        padding-top: 0px;
    }
    form span{
        font-size: 12px;
    
    }
    /* .box2{
        width: 80%;
    } */
    /* .heading p{
        font-size: 30px;
    } */
}

@media screen and (max-width:550px) {
    .heading{
        width: 80%;
    }
    .searchform {
        width: 70%;
        margin-top: 20px;
       
    }
}
@media screen and (max-width:420px) {
    .heading h1{
        font-size: 20px;
    }
    .heading P{
        font-size: 12px;
    }
    .sbtn>button{
        font-size: 16px;
        margin-top: 15px;
    }
    .searchform {
        width: 90%;
        margin: auto;
        margin-top: 20px;
        background-color: rgb(157, 157, 157,0.2);
    }
}