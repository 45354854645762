.userDetails {
    padding: 10px 15px 35px 15px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    background-color: white;
    border-radius: 6px;
    box-shadow: 2px 2px 2px rgba(0, 0, 0, 0.3);
    @media screen and (max-width: 942px) {
        font-size: 11px;
    }
}

.detail {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.detail .row {
    display: flex;
    gap: 20px;
    
    @media screen and (max-width: 705px) {
        display: flex;
        flex-direction: column;
    }
}

.detail .row>div {
    flex: 1;
}

.headingContainer {
    border-bottom: 1px solid gray;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
}

.headingContainer h6 {
    font-weight: bold;
}