.propertyContainer{
    display: flex;
    justify-content: space-between;
    /* border: 2px solid black; */
    margin-top: 20px;
    padding: 40px;
}

.left{
    width: 55%;
    border-radius: 10px;
    padding: 10px;
    /* border: 2px solid red; */
}

.name{
    margin: 5px 0;
    padding: 5px 0;
}

.location{
    margin: 5px 0;
    display: flex;
    align-items: center;
}

.type{
    background-color: green;
    color: white;
    padding: 2px 5px;
    margin: 5px;
    width: fit-content;
}

.properties{
    display: flex;
    flex-wrap: wrap;
}

.properties>div{
    margin: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 200px;
}

.properties>div>p{
    padding: 4px;
}

.right{
    width: 35%;
    border-radius: 10px;
    padding: 10px;
    /* border: 2px solid blue; */
}

@media screen and (max-width: 800px) {
    .propertyContainer {
      flex-direction: column;
    }

    .left{
        width: 100%;
    }

    .right{
        width: 100%;
    }
  }