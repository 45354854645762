.formcontainer{
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid red;
    width: 100vw;
    height: 100vh;
    background-color: rgb(128, 128, 128,0.2);
   
}
.Authform{
    width: 60%;
    background: white;
}
.closeicon{
  margin-left: auto;
 
  /* border: 2px solid red; */
}
.closeicon span{
  font-size: 24px;
  color: black;
}

.forminputs {
    padding: 16px;
    display: flex;
    flex-direction: column; 
    align-items: center;
}

.inputText {
    width: 50%;
    padding: 15px;
    margin: 5px 0 22px 0;
    display: inline-block;
    border: none;
    background: #f1f1f1;
  }
  
 .inputText:focus{
    background-color: #ddd;
    outline: none;
  }

  .emailinp{
    display: block;
    width: 55%;
    /* border: 2px solid red; */
  }
  .emailinp label{
    margin: 6px;
  }
.btn{
    background-color: #007bff;
    width: 70%;
    color: #fff;
    outline: none;
    border: none;
    padding: 10px;
    font-size: 18px;
    font-weight: 400;
    border-radius: 10px;

}
.Googlebtn{
  background-color: #fff;
  width: 60%;
  color: #000000;
  outline: none;
  border: 1px solid rgb(33, 33, 33);
  padding: 10px;
  font-size: 16px;
  font-weight: 400;
  border-radius: 10px;
  margin-top: 10px;
  cursor: pointer;
}
.signin{
    width: 80%;
    margin-top: 1.5rem;
    text-align: center;
   
    /* border: 2px solid red; */
}
.signin a{
  margin-top: 0.5rem;
    color: #007bff;
}
.Orcontent {
  margin: 10px auto;
  width: 55%
}

.or {
  text-align: center;
  font-size: 20px;
  background:
    linear-gradient(#000 0 0) left,
    linear-gradient(#000 0 0) right;
  background-size: 44% 1px;
  background-repeat: no-repeat;
}
.Socialsignin{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}


@media screen and (max-width:900px) {
  .formcontainer{
    justify-content: center;
    align-items: center;
  }
  .forminputs{
    margin: auto;
  }
 .inputText{
    width: 90%;
  }
  .emailinp{
    width: 100%;
    margin: 0px;
  }
  
}
@media screen and (max-width:500px) {

  .signin>button{
   
    width: 100%;
  }
  
}
@media screen and (max-width:330px) {
  .signin>button,p{
    font-size: 14px;
    padding: 10px;
  }
  .emailinp{
    font-size: 14px
  }
}