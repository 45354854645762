.emailContainer{
    display: flex;
    justify-content: center;
    align-items: center;  
    width: 100%;
    height: 100%;  
}
.emailBox{
    /* border: 2px solid red; */
    width:30rem;
    height: 20rem;
    margin-top: 10rem;
}
.emailBoxImg{
    text-align: center;
    width: 100%;
    height: 70%;
    /* border: 2px solid rgb(0, 0, 0); */
    background-size: cover;
}
.emailContent{
    text-align: center;
    
    width: 100%;
    height: 30%;
    /* border: 2px solid rgb(22, 64, 171); */
}
.emailContent>h2{
    margin-top: 1rem;
}
.emailBoxImg>img{
    
    width: 50%;
    height: 90%;
    object-fit: cover;
   
}
.emailLink{
    color: blue;
    border-bottom: 2px solid blue;
}
