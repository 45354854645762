@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;1,200&family=Quicksand&display=swap");

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.body {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
  background-color: #fff;
}
.container {
  max-width: 90%;
  margin: auto;
  /* border: 2px solid red; */
}
.flex {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
a {
  text-decoration: none;
  color: #3c3c3c;
}
.background {
  padding: 60px 0;
  position: relative;
  background-color: #f7f9fc;
}

.grids {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 30px;
}

label {
  color: #2d3954;
  font-size: 14px;
}
.mtop {
  margin-top: 50px;
}
h4 {
  font-weight: 500;
  margin-top: 10px;
}

.shadow {
  box-shadow: 0 0 20px 0 rgb(121 121 38 / 18%);
  /* border: 2px solid red; */
  width: 90%;
  height: 95%;
  border-radius: 10px;
}
.padding {
  padding: 20px 0px;
}
.grid3 {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 20px;
}
img {
  width: 100%;
  height: 100%;
}
.grid4 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 20px;
}
