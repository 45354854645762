*{
    margin: 0;
    padding: 0;
}
.listcontainer{
    display: flex;
    justify-content: center;
    margin-top: 20px;
    /* border: 2px solid red; */
    padding: 10px;
}

.listwrapper{
    width: 80%;
    /* max-width: 1024px; */
    display: flex;
    padding: 0 20px 20px 20px;
    /* border: 2px solid black; */
    background-color: #f7f9fc;
    border-radius: 15px;
    
}
.listsearch{
    flex: 1;
    /* border: 2px solid rgb(255, 8, 8); */
    background-color: #cac0a4;
    padding: 5px;
    margin-top: 20px;
    border-radius: 10px;
    position: sticky;
    top: 10px;
    display: flex;
    flex-direction: column;
    height: 30rem;
}
.lstitle{
    margin-bottom: 10px;
}
.listresult{
    flex:3;
    margin-left: 30px;
    border-left: 1px solid grey;
    /* border: 2px solid rgb(255, 10, 10); */
}
.listitem{
    margin: 5px;
   
}
.listitem label{
    display: block;
    font-size: 18px;
    color: black;
    margin-top: 5px;
}
.listitem input{
    width: 90%;
    background-color: #fff;
    line-height: 4vh;
    border-radius: 5px;
    padding: 3px;
    border: none;
}
.listitem input::placeholder{
    padding: 3px 3px;
}
.searchbtn{
    /* text-align: center; */
    display: flex;
    justify-content: space-between;
    /* border: 2px solid red; */
    /* padding: 0px 10px; */
    margin: 10px;
}
.searchbtn button{
    font-size: 22px;
    padding: 5px 10px;
    border-radius: 5px;
    background-color: #0071c2;
    color: #fff;
    border: none;
    cursor: pointer;
    margin-top: 15px;
}

.btnSearch{
    margin: 8px;
    background-color: white;
    padding: 4px 8px;
    font-size: 1rem;
    color: green;
    outline: none;
    border: 1px solid black;
    cursor: pointer;
}

.btnSearch:hover{
    background-color: green;
    color: white;
}

.not-found{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width:950px)  {
    .searchItem {
        grid-gap: 10px;
        padding: 10px;
    }
    .card{
        width: 100%;
    }
    
}
/* @media screen and (max-width:350px) {
    .card {
        height: 26rem;
    }
} */
@media screen and (max-width:520px)  {
    .searchbtn>button{
        font-size: 12px;
    }
    .searchItem{
        grid-template-columns: repeat(1,1fr);
    }
    .sibtn {
        margin: 0px;
    }
   
    .searchItem .card{
        width: 100%;
    }
    .searchItem .siInfo>h2{
        font-size: 14px;
    }
    .siInfo>span{
        margin: 2px;
    }
    .siInfoType{
        font-size: 14px;
    }
}
@media screen and (max-width:640px) {
    .sibtn{
       margin-bottom: 1rem;
    }
}

@media screen and (max-width:850px)  {
    .listwrapper{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .listsearch{
        width: 90%;
        height: 3rem;
        position: relative;
        margin-top: 0px;
        top: 0px;
        
    }
    .listresult{
        margin: 0px;
    }
    .serachData{
        display: grid;
        grid-template-columns: repeat(2,1fr);
        grid-gap: 0px;
    }
    .listitem{
        margin: 0px;
    }
   
    .card>h2{
        font-size: 14px;
    }
    .card .siInfo>span{
        margin: 2px;
    }
     .btnn .a_btnn{
        font-size: 16px;
    }
    .searchbtn{
        /* padding: 0px 8rem; */
        width: 10rem;
        margin: auto;
        margin-top: 10px;
    }
    .searchbtn>button{
        /* text-align: center; */
        font-size: 16px;
       margin: 10px 0px;
    }
   
}


@media screen and (max-width:1000px)  {
    .searchItem {
        padding: 10px;
    }
   
}


@media screen and (max-width:1200px)  {
    .listwrapper{
        width: 100%;
    }
}