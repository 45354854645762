* {
  margin: 0;
  padding: 0;
}
p {
  color: black;
}
.searchItem {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  padding: 20px;
  /* background-color: #f7f9fc;*/
}
.card {
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: #fff;
  /* border: 2px solid black; */
}
.siImg {
  width: 100%;
  height: 18rem;
  background-size: cover;
  transition: transform 0.3s ease;
}

.siImg:hover {
  transform: scale(1.1);
}

.card img {
  width: 100%;
  border-radius: 2px;
  /* object-fit: cover; */
}
.siInfo {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  margin-bottom: 10px;
  border-top: 1px solid rgba(181, 181, 181, 0.739);
}
.siInfo .text {
  border-radius: 5px;
  padding: 5px;
  margin: 3px;
}
.siInfo p {
  padding: 5px;
  /* margin: 10px;
     */
}
.sibtn {
  text-align: center;
  margin: 10px;
  margin-bottom: 0rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}
.btnn {
  background-color: #268d1f;
  /* border-radius: 10px; */
  border: none;
  color: #fff;
  font-size: 16px;
  margin: 10px;
  cursor: pointer;
}
.sibtn button {
  padding: 5px 10px;
}
.contactbtn {
  padding: 0px 10px;
  margin-bottom: 2rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.contactLink {
  text-decoration: none;
  color: #268d1f;
}
.a_btnn {
  background-color: #268d1f;
  /* border-radius: 10px; */
  border: none;
  color: #fff;
  font-size: 16px;
  margin: 5px;
  cursor: pointer;
  padding: 4px 10px;
}
.a_btnn:hover,
.btnn:hover {
  border-radius: 8px;
}
.phoneLink {
  cursor: pointer;
  color: #268d1f;
}
.phoneLink:hover {
  text-decoration: underline;
}
.emailLinkk {
  cursor: pointer;
  color: #268d1f;
}
.emailLinkk:hover {
  text-decoration: underline;
}

@media screen and (max-width: 520px) {
  .searchItem {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 600px) {
  .phoneInfo {
    margin: auto;
  }
  .emailInfo {
    margin: auto;
  }
}
