
.loginpage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2224;
    
}
.registerpage{
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 2224;
 }
.slider{
    position: sticky;
    z-index: 2228;
}