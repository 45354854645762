.recenttitle {
  text-align: center;
}
.recenttitle h1 {
  margin: 0px;
  margin-top: 20px;
  padding: 0px;
  font-size: 40px;
}
.recenttitle p {
  color: #2d3954;
  margin: 0px;
  padding: 0px;
  margin-top: 5px;
}
.shadow h4 {
  margin: 0px;
  padding: 0px;
}
.shadow p {
  margin: 0px;
  padding: 0px;
}
/* .category>span{
    padding: 5px 5px;
    margin: 5px 5px;
} */
.btn2 {
  margin-right: 20px;
  padding: 5px 8px;
  font-size: 18px;
  border-radius: 10px;
  background-color: #27ae60;
  color: rgb(255, 255, 255);
  border: none;
}
.btn2:hover {
  /* background-color: rgb(219, 64, 146); */
  cursor: pointer;
  color: white;
}
.recent .text {
  padding: 10px;
}
.recent .category span {
  padding: 4px 15px;
  border-radius: 2px;
  font-weight: 600;
  font-size: 15px;
  display: inline-block;
}
.recent .category {
  font-size: 20px;
  color: #bec7d8;
  padding: 10px;
}
.recent p {
  color: #72809d;
}
.recent .button {
  border-top: 1px solid #efe8f3;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 10px;
  /* border: 2px solid red; */
}
.pagination-buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 1100px) {
  .btn2 {
    margin-right: 20px;
    padding: 5px 8px;
    font-size: 12px;
  }
  .content .loc {
    font-size: 12px;
  }
  .button > span {
    font-size: 12px;
  }
}

@media screen and (max-width: 720px) {
  .grid3 {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 5px;
  }
}
@media screen and (max-width: 460px) {
  .grid3 {
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 5px;
  }
}

@media screen and (max-width: 500px) {
  .text > h4 {
    font-size: 12px;
  }
  .content .category > span {
    font-size: 12px;
    padding: 1px 3px;
  }
  .content .category {
    padding: 0px;
  }
}
@media screen and (max-width: 385px) {
  .text > h4 {
    font-size: 10px;
  }
  .content .category > span {
    font-size: 10px;
    padding: 1px 3px;
  }
  .recenttitle p {
    font-size: 12px;
  }
  .content .category {
    padding: 0px;
  }
  .btn2 {
    margin-right: 15px;
    padding: 3px 5px;
    font-size: 10px;
  }
  .loc {
    font-size: 10px;
  }
  .content .text > p {
    font-size: 10px;
  }
  .button > span {
    font-size: 10px;
  }
}
